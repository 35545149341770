import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost;

    function convertDateTime(e) {
        return new Intl.DateTimeFormat("de-DE", {
            year: "numeric",
            month: "long",
            day: "2-digit"
          }).format( new Date(e));
    }

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
                <script defer src="https://commento.nightdreamer.me/js/commento.js"></script>
            </Helmet>
            <Layout>
                <div className="container container-post">
                    <article className="content">
                        <section className="post-full-content">
                            <span>{convertDateTime(post.published_at)}</span>
                            <h1 className="content-title">{post.title}</h1>
                        </section>
                        { post.feature_image ?
                            <figure className="post-feature-image">
                                <GatsbyImage image={post.feature_image_sharp.childImageSharp.gatsbyImageData} alt={post.title} />
                            </figure> : null }
                        <section className="post-full-content">
                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                    <div id="commento"></div>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
